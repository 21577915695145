@import "styles/constants.scss";

.PaymentInformationText {
  font-size: $large-font-size;
  color: $primary;
}

.PaymentInformation {
  background-color: $sand;
  width: max-content;
  padding: 20px 50px;
  margin: auto;
  width: 900px;
}

.Prices{
  position: relative;
}
.PricesContainer{
  position: absolute;
  display: flex;
  right: 237px;
  gap: 36px;
  bottom: 50px;
}
.PricesContainer.ForNidekTimeline {
  right: 309px;
  gap: 83px;
}

.PriceOrthoptist, .PriceOphtalmologist
{
  background : #f4f5f7;
  padding:10px;
  border-radius: 4px;
  width: 250px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap:5px;
}
.PriceOphtalmologist
{
}
.MoreInformation {
  font-size: $large-font-size;
  color: $primary-light;
}
.Infos {
  border-radius:  8px;
    border: 1px solid #73B3E9;
    background:  #E9F3FC;
    padding: 24px;
    margin-bottom: 50px;
    font-size: $large-font-size;
    justify-content: center;
    color: $primary;
    display: flex;
    align-items: center;
    gap: 20px;
  }