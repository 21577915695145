@import "../../styles/constants.scss";

.H1 {
  font-size: $xxxlarge-font-size;
  color: $primary;
  font-weight: bold;
}

.H2 {
  font-size: $xlarge-font-size;
  color: $primary;
}